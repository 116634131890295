var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"section-content"}},[_c('Modal',{ref:"modalReport",attrs:{"no-close-on-esc":"","no-close-on-backdrop":"","size":_vm.relatorioData
      && _vm.relatorioData.reportType
      && _vm.relatorioData.reportType.type
      ? _vm.reportSizes[_vm.relatorioData.reportType.type] : 'lg',"centered":""}},[_c('DynamicReport',{attrs:{"reportProcesso":_vm.relatorioData.reportProcesso,"reportsMateriais":_vm.relatorioData.reportMateriais,"reportType":_vm.relatorioData.reportType}})],1),_c('div',{staticClass:"info-ciclo-card card invision-card borderless p-card div-container p-4"},[_c('div',{staticClass:"table-header"},[_c('h5',[_vm._v("Materiais do protocolo")]),_c('b-button',{staticClass:"btn-table-header",attrs:{"variant":"outline-dark"}},[(_vm.printable && !_vm.idProcessoCicloBusy)?_c('printer-icon',{staticClass:"w30px mouse align-middle",on:{"click":function($event){return _vm.printRelatorio()}}}):_c('b-spinner',{attrs:{"variant":"secondary","small":"","label":"Spinning"}})],1)],1),_c('b-table',{ref:"processos-table",attrs:{"name":"tabela_ciclo","striped":"","responsive":"","items":_vm.material,"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(obs)",fn:function(){return undefined},proxy:true},{key:"cell(detailsMaterialGenerico)",fn:function(){return undefined},proxy:true},{key:"cell(ordem)",fn:function(ref){
      var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1))]}},{key:"cell(status)",fn:function(form){return [(form.item.obs)?_c('div',{staticClass:"status-base change-color-yellow"},[_vm._v(" Liberado ")]):(form.item.status === null)?_c('div',[_vm._v(" - ")]):_c('div',{staticClass:"status-base",class:form.item.status === 'Entregue' ? 'change-color-green' :
            form.item.status === 'Forçado' ? 'change-color-yellow' :
            'change-color-grey'},[_vm._v(" "+_vm._s(form.item.status)+" ")])]}},{key:"cell(details)",fn:function(row){return [(row.item.isGenerico)?_c('img',{staticClass:"clipboard-icon",staticStyle:{"cursor":"pointer"},attrs:{"src":require("../../../assets/img/clipboard.svg")},on:{"click":row.toggleDetails}}):_vm._e(),(row.item.obs)?_c('info-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(
              row.item.obs
            ),expression:"\n              row.item.obs\n            ",modifiers:{"hover":true,"top":true}}],staticClass:"ml-3 info-icon"}):_vm._e()]}},{key:"row-details",fn:function(row){return [_c('b-card',[_c('b-row',{staticClass:"mb-2"},[_c('b-col',[_vm._v("Qtd: "+_vm._s(row.item.detailsMaterialGenerico[0]))]),_c('b-col',[_vm._v("Preparo: "+_vm._s(row.item.detailsMaterialGenerico[1]))]),_c('b-col',[_vm._v("Esterilização: "+_vm._s(row.item.detailsMaterialGenerico[2]))]),_c('b-col',[_vm._v("Saída Setor: "+_vm._s(row.item.detailsMaterialGenerico[3]))]),(row.item.detailsMaterialGenerico[4] ==='retornoExpurgo')?_c('b-col',[_vm._v(" (Retornou ao Expurgo) ")]):_vm._e()],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }